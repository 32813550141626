



















import { Component, Vue } from 'vue-property-decorator';
import FormDataManager from '@/components/form-data-manager/form-data-manager.vue';
import RiverHeader from '@/views/layout/components/river-header/index.vue';
import LeftMenu from './left-menu.vue';
import UserUtil from '@/utils/UserUtil';
import { THEME_DATA } from '@/mock/ThemeMock';
import AppApi from '@/api/application/AppApi';
@Component({
  name: 'usuallyApplication',
  components: { FormDataManager, LeftMenu, RiverHeader }
})
export default class FormDataList extends Vue {
  platIconTitleData: any = {};
  pageName: string = '';
  applicationId: string = '';
  menusData: any[] = [];
  curForm: any = {};
  formList: any[] = [];
  flowList: any[] = [];

  /**
   * created
   */
  created() {
    this.getPlatIconTitle();
    this.initData();
  }
  initData(): void {
    let query = this.$route.query;
    this.pageName = query.name as string;
    this.applicationId = query.applicationId as string;
    this.buildMenusData();
  }

  buildMenusData() {
    // 固定数据
    let menusData = [
      {
        appCode: 'form',
        menuCode: 'form-001',
        menuId: 'form-001',
        menuName: '表单',
        menuPid: '-1',
        menuSort: 1,
        children: [],
        iconCalssName: 'el-icon-s-order'
      },
      {
        appCode: 'flow',
        menuCode: 'flow-001',
        menuId: 'flow-001',
        menuName: '流程',
        menuPid: '-1',
        menuSort: 2,
        children: [],
        iconCalssName: 'el-icon-sort'
      }
    ];
    // 插入子节点
    Promise.all([this.getFormListData(), this.getFlowListData()]).then(res => {
      let formList = [];
      this.formList.map((item, index) => {
        if (item.link.status === '1') {
          let i = item;
          i.appCode = item.id;
          i.menuCode = item.id;
          i.menuId = item.id;
          i.menuName = item.link.displayName;
          i.menuPid = 'flow-001';
          i.menuSort = index + 1;
          i.children = [];
          formList.push(i);
        }
      });
      menusData[0].children = formList;
      this.menusData = menusData;
    });
  }

  /**
   * 获取表单列表
   */
  async getFormListData(applicationId?: string): Promise<void> {
    let res: Record<any, any> = await AppApi.getApplicationLinkFormList({
      applicationId: applicationId ? applicationId : this.applicationId
    });
    if (res.code == '1' && res.data) {
      this.formList = res.data;
    } else {
      this.$message.error(res.message);
    }
  }
  // 获取流程列表
  async getFlowListData(applicationId?: string): Promise<void> {
    let res: Record<any, any> = await AppApi.getApplicationLinkProcessList({
      applicationId: applicationId ? applicationId : this.applicationId
    });
    if (res.code == '1' && res.data) {
      this.flowList = res.data;
    } else {
      this.$message.error(res.message);
    }
  }

  /**
   * 获取登录页面，布局页面配置
   * @param {number} param
   * @returns {number}
   */
  getPlatIconTitle(): void {
    let logoId = UserUtil.getLoginTenants()?.logo || '';
    this.platIconTitleData = { ...THEME_DATA, icontitleCompanyLogo: logoId, icontitleDoorLogo: logoId };
    this.platIconTitleData.icontitlePlatCnname = this.$t(this.platIconTitleData.icontitlePlatCnname);
    this.platIconTitleData.icontitleCompany = this.$t(this.platIconTitleData.icontitleCompany);
    this.platIconTitleData.icontitleDoorTitle = this.$t(this.platIconTitleData.icontitleDoorTitle);
  }

  menuClick(data) {
    let curForm = {
      code: data.link.code,
      id: data.linkId
    };
    this.curForm = curForm;
  }
}
